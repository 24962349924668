<template>
    <CreditApplicationForm v-editable="blok" :data="formContent" />
</template>

<script setup lang="ts">
import CreditApplicationFormType from '@/types/CreditApplicationFormType';
import CreditApplicationForm from '~/components/page-building/credit-application-form/CreditApplicationForm.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const formContent = computed<CreditApplicationFormType>(() => {
    return {
        title: props.blok.title,
        requirementsTitle: props.blok.requirements_title,
        requirements: props.blok.requirements,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});
</script>
